import React from 'react'
import LayoutKyr from '../components/layout-kyr'
import ShareSocial from '../components/social-share-buttons'

export default function Test() {
  return (
    <LayoutKyr>
      Hello from Teest
      <ShareSocial/>
    </LayoutKyr>
  )
}
